import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthorizationType } from '../../enums/authorizationType.enum';
import { IAuthDto } from '../../models/authDto.model';
import { RestService } from '../../services/rest.service';
import { MatDialog } from '@angular/material/dialog';
import { LegalComponent } from '../legalComponent/legal.component';
import { DialogLegalData } from '../../models/legal.dialog.model';

@Component({
  selector: 'app-legal-decision',
  templateUrl: './legalDecision.component.html',
  styleUrl: './legalDecision.component.scss'
})
export class LegalDecisionComponent {

  @Input() entryFormState: AuthorizationType = AuthorizationType.NOT_NECESSARY;

  constructor(
    private _rest: RestService,
    public _dialog: MatDialog
  ) { }

  public NeedAuthorization = async(item: AuthorizationType, email: string) => await new Promise<boolean>(async(resolve) => resolve(await this.hasUserAnyDocuments(email, item)));

  private hasUserAnyDocuments = async (email: string, authType: AuthorizationType): Promise<boolean> => {
    let response = await this._rest.GetFormAuthorization(email).toPromise();

    let authDto = {
      email: email,
      message: response.value?.message,
      type: authType,
      state: response.value?.authorizationState
    } as IAuthDto;

    switch(authType) {
      case AuthorizationType.NOT_NECESSARY:
        return true;
    }

    this.openDialog(authDto);

    return false;
  }


  private openDialog = (authDto: IAuthDto) => {

    let descDictionary = [
      "",
      $localize`Zadanou sumu nemůžeme z důvodu bezpečnosti převést pro uživatele, kterého nemáme v databázi jako identifikovanou osobu. Z tohoto důvodu je třeba, aby jste se nám registroval a my jsme mohli prokázat splnění podmínek AML.`,
      $localize`Bohužel se nám na zaslaných dokumentech nepodařilo ověřit Vaši totožnost. ${authDto.message === undefined ? "" : authDto.message}`,
      "",
      $localize`Váš požadavek byl zamítnut. ${authDto.message === undefined ? "" : authDto.message}`
    ];

    let dialogRef = this._dialog.open(LegalComponent, {
      data: {
        title: $localize`Ověření uživatele`,
        description: descDictionary[authDto.type]
      } as DialogLegalData,
      maxWidth: "560px"
    });
    dialogRef.componentInstance.detailsForm?.controls['email'].patchValue(authDto.email);
    dialogRef.componentInstance.dialogState = authDto.state;
    dialogRef.componentInstance.dialogType = authDto.type;
  }

}
