@if(entryFormState == 0)
{
} @else if(entryFormState == 1){
    <p class="badge-btn badge-btn-secondary">Odsouhlasení podmínek</p>
} @else if(entryFormState == 2){
    <p class="badge-btn badge-btn-secondary">Předložení dokladů</p>
} @else if(entryFormState == 10){
    <p class="badge-btn badge-btn-secondary">IN_PROGRESS</p>
} @else if(entryFormState == 99){
    <p class="badge-btn badge-btn-secondary">REJECTED</p>
}