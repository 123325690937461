import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Inject, Input, Output, ViewChild} from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { AuthorizationState } from "../../enums/authorizationState.enum";
import { FilesPrototype, IFilesModel } from "../../models/customFiles.model";
import { DialogLegalData } from "../../models/legal.dialog.model";
import { TransactionDetail } from "../../models/transaction.dto";
import { AppService, IAppServiceModel } from "../../services/component.service";
import { RestService } from "../../services/rest.service";
import { AuthorizationType } from "../../enums/authorizationType.enum";
import {AuthorizationPupupService} from "../../services/authorizationPupup.service";

@Component({
    selector: "legal-window",
    templateUrl: "./legal.component.html",
    styleUrls: ["./legal.component.scss"]
}) export class LegalComponent {

    @ViewChild("basicStepper") basicStepper: MatStepper = null;
    @Input() public dialogState: AuthorizationState = null;
    @Input() public dialogType: AuthorizationType = null;

    public formStateDto: FormData = new FormData();
    public filesToSendA: Array<IFilesModel> = [];
    public filesToSendB: Array<IFilesModel> = [];
    public uploaded: boolean = false;
    public uploading: boolean = false;
    public uploadProgress: number = 0;
    public fileLimit: number = 1;

    @ViewChild("stepper") stepper: MatStepper;

    @Input() public userId: number;
    public fileTypeAvalible: string[] = [".jpg",".png",".jpeg"];
    public uId: string | number;
    private RouterSub: Subscription;
    /**
     * Microtransaction used to authorize request
     */
    @Input() public _microtransaction: TransactionDetail;

    /**
     * Form include data from customer
     */
    @Input() public detailsForm: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(null, {
            validators:[
                Validators.required
            ]
        }),
        // TODO: email should be verified by SMS
        phone: new UntypedFormControl(null, {
            validators:[
                Validators.required
            ]
        }),
        email: new UntypedFormControl(null, {
            validators:[
                Validators.required,
                Validators.email
            ]
        })
    });

    /**
     * Identity card A, back and front side
     */
    @Input() public proofFormA = new FormGroup({
        photoA: new FormControl(null, {
            validators:[
                Validators.required
            ]
        }),
        photoB: new FormControl(null, {
            validators:[
                Validators.required
            ]
        })
    });
    

    @Input() public formBasicAuth = new FormGroup({
        agreement: new FormControl(false,{
            validators:[
                Validators.requiredTrue
            ]
        })
    });

    constructor(
        private _rest: RestService,
        private _app: AppService,
        private _activeRoute: ActivatedRoute,
        private _toast: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: DialogLegalData,
        private _sanitizer: DomSanitizer,
        private _authPopupService: AuthorizationPupupService
    ) {
        this._app.addTitle = {
            pageModel: $localize`Kryptoměnový terminál`,
            hideH1: true,
            pageMetaTitle: $localize`HD Crypto s.r.o | Kryptoměnový terminál`
        } as IAppServiceModel;

        this.uId = 22;
    }

    ngOnInit(): void {
        this.RouterSub = this._activeRoute.params.subscribe(p => {
            console.log(p);
        })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.RouterSub?.unsubscribe();
    }

    formSubmited = () => {
        
        this.uploaded = false;
        this.uploading = true;
        this.uploadProgress = 0;

        let data = new FormData();
        data.append("Name", this.detailsForm.get("name").value);
        data.append("Phone", this.detailsForm.get("phone").value);
        data.append("Email", this.detailsForm.get("email").value);
        data.append("PhotoA", this.filesToSendA[0]);
        data.append("PhotoB", this.filesToSendB[0]);

        this._rest.PostFormAuthorization(data).subscribe((event) => {
               if (event.type === HttpEventType.UploadProgress) {
                   // This is an upload progress event. Compute and show the % done:
                   const p = Math.round(100 * event.loaded / event.total);
                   //console.log(`File is ${p}% uploaded.`);
                   this.uploadProgress = p;
               } else if (event instanceof HttpResponse) {
                    //console.log('File is completely uploaded!');
                    this.uploaded = true;
                    this.uploading = false;
                    this.stepper.next();
               }
           },
           err => {
               console.error(err);
               this.uploaded = true;
               this.uploading = false;
               this._toast.open($localize`Bohužel všechna data se nepovedla uložit. Zkuste si zkontrolovat velikost odesílaných soubor, která nesmí přesahovat celkově 9 MB a pokuzte se je odeslat ještě jednou.`);
           }
        )
    }

    basicFormSubmited = () => {

        let data = new FormData();
        data.append("Name", this.detailsForm.get("name")?.value);
        data.append("Phone", this.detailsForm.get("phone")?.value);
        data.append("Email", this.detailsForm.get("email")?.value);
        data.append("UserAgreed", this.formBasicAuth.get("agreement")?.value?.toString());

        this._rest.PostFormAuthorizationBasic(data)
        .toPromise()
        .then(r=>{
            this.basicStepper.next();
        });

    }

    getSafeUrl = (url:string) => this._sanitizer.bypassSecurityTrustUrl(url);

    add(files: FileList, type: number) {
        Array.from(files).forEach(m=> {
            const extension = m.name.replace(/^.*(?=\.)/,"");
            const file = new FilesPrototype(m, this.fileLimit).File;

            if(this.fileTypeAvalible.includes(extension) && file.sizeInMb <= this.fileLimit){
                switch(type){
                    case 0:
                        this.filesToSendA = [];
                        this.filesToSendA.push(file);
                        break;
                    case 1:
                        this.filesToSendB = [];
                        this.filesToSendB.push(file);
                        break;
                }
            } else{
                this._toast.open($localize`Soubor není možné uložit. Ujistěte se, ře soubor nepřevyšuje velikost ${this.fileLimit}MB a zároveň odpovídá jednomu z těchto formátů ${this.fileTypeAvalible.join(",")}`,$localize`Ok`,{
                    panelClass:"bg-warning"
                })
            }
        });
    }

    removed = (type: number) =>{
        switch(type){
            case 0:
                this.proofFormA.get("photoA")?.patchValue(null);
                break;
            case 1:
                this.proofFormA.get("photoB")?.patchValue(null);
                break;
        }
        
    }

    authPopupClosed = (event: Event) => this._authPopupService.authPopupClosed();

}