import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {IonResult} from "../models/ion.result.model";
import { map } from "rxjs/operators";
import { environment } from "../environments/environment";
import { CourseDtoIn, CourseDtoOut } from "../models/course.model";
import { CurrencyDtoIn, CurrencyDtoOut, FiatCurrencyDtoOut } from "../models/currency.model";
import { TerminalTransactionDtoOut, TerminalTransactionEmailDtoIn, TerminalTransactionNotificationEmaillDtoIn, TransactionDetail, TransactionDtoIn, TransactionSelectedDtoIn } from "../models/transaction.dto";
import { BankAccountDtoOut, IBankAccount, IBanksInfo } from "../models/banks.model";
import { Observable } from "rxjs";
import { FormStateModelDtoIn } from "../models/formStateDtoIn.model";
import { TerminalIdentificationDto, TerminalIdentificationModel } from "../models/terminalIdentification.model";

@Injectable(
    {
        providedIn: "root"
    }
) export class RestService {

    constructor(private http: HttpClient) {}
    /**
     * 
     * @param obj Returns course for specific parameters
     * @returns 
     */
    public GetCourse = (obj: CourseDtoIn) => this.http.get<CourseDtoOut>(`/api/general/v1/course?${this.GetQueryUrl(obj)}`).pipe<CourseDtoOut>(this.mapSuccessResponse());

    /**
     * Returns all cryptocurencies with values
     * @param obj Data transfer object
     * @returns Currencies in collection
     */
    public GetCurrencies = (obj: CurrencyDtoIn) => this.http.get(`/api/general/v1/currencies?${this.GetQueryUrl(obj)}`).pipe<CurrencyDtoOut[]>(this.mapSuccessResponse());

    /**
     * Returns all cryptocurencies with values
     * @param obj Data transfer object
     * @returns Currencies in collection
     */
    public GetFiatCurrencies = () => this.http.get(`/api/general/v1/fiatcurrencies`).pipe<FiatCurrencyDtoOut[]>(this.mapSuccessResponse());

    /**
     * Returns created transaction with QR Code
     * @param body Source
     * @returns 
     */
    public PostTransaction = (body: TransactionDtoIn) => this.http.post(`/api/general/v1/transactions`, body).pipe<TerminalTransactionDtoOut>(this.mapSuccessResponse());

    /**
     * Returns updated transaction
     * @param body Source
     * @returns {Observable<TerminalTransactionDtoOut>}
     */
    public PostSelectedTransaction = (body: TransactionSelectedDtoIn):Observable<TerminalTransactionDtoOut | any> => this.http.post(`/api/general/v1/transactions/merchant`, body).pipe<TerminalTransactionDtoOut>(this.mapSuccessResponse());

    /**
     * Returns data of transaction
     * @param hash Unified transaction hash
     * @returns {Observable<TransactionDetail>} TransactionDetail
     */
    public GetTransactionByHash = (hash: string): Observable<TransactionDetail> => this.http.get(`/api/general/v1/transactions/${hash}`).pipe<TransactionDetail>(this.mapSuccessResponse());

    /**
     * Change object of Transaction and state
     * @param TxId ID of transaction
     * @param State State to be updated
     * @returns 
     */
    public PutTransaction = (TxId: number, State: number ) => this.http.put(`/api/general/v1/transactions/state/${TxId}/${State}`,null).pipe<boolean>(this.mapSuccessResponse());

    /**
     * Post email changes to api
     * @param model contains transactionID as INT and email to set
     * @returns {Observable<TerminalTransactionEmailDtoIn>}
     */
    public PostTransactionEmail = (model:TerminalTransactionEmailDtoIn): Observable<TerminalTransactionEmailDtoIn> => this.http.post(`/api/general/v1/transactions/email`,model).pipe<TerminalTransactionEmailDtoIn>(this.mapSuccessResponse());

    /**
     * Post request to clone username to notificationemail
     * @param value true/false
     * @returns {Observable<String>} notificationemail value
     */
    public PostTransactionNotificationEmailClone = (value: TerminalTransactionNotificationEmaillDtoIn): Observable<String> => this.http.post(`/api/general/v1/transactions/email/notification`,value).pipe<String>(this.mapSuccessResponse());

    /**
     * Get all available params about banks
     * @returns {Observable<IonResult<Array<IBanksInfo>>>} Array ov items
     */
    public GetBanksInformations = (): Observable<IonResult<Array<IBanksInfo>>> => this.http.get(`/api/general/v1/banks`).pipe<IonResult<Array<IBanksInfo>>>(this.mapSuccessResponse());

    /**
     * Get information about bank account
     * @param
     * @returns {Observable<IonResult<BankAccountDtoOut>>}
     */
    public GetBankAccountInfo = (body: IBankAccount):Observable<IonResult<BankAccountDtoOut>> => this.http.post(`/api/general/v1/bankaccount`, body).pipe<IonResult<BankAccountDtoOut>>(this.mapSuccessResponse());

    /**
     * Sends authorization ifno from filled form and saves it
     * @param body Data transfer object
     * @returns 
     */
    public PostFormAuthorization = (body: FormData):Observable<any> => this.http.post(`/api/formState/v1/`, body,{
        reportProgress: true,
        observe: 'events'
    }).pipe<IonResult<FormStateModelDtoIn> | any>(this.mapSuccessResponse());

    /**
     * Sends authorization ifno from filled form and saves it
     * @param body Data transfer object
     * @returns 
     */
    public PostFormAuthorizationBasic = (body: FormData):Observable<IonResult<FormStateModelDtoIn>> => this.http.post(`/api/formState/v1/basic/`, body)
    .pipe<IonResult<FormStateModelDtoIn>>(this.mapSuccessResponse());

    /**
     * Returns authorization info for specific email
     * @param body Data transfer object
     * @returns 
     */
    public GetFormAuthorization = (email:string):Observable<IonResult<TerminalIdentificationDto>> => this.http.get(`/api/formState/v1/${email}`).pipe<IonResult<TerminalIdentificationDto>>(this.mapSuccessResponse());

    /**
     * Post files to API
     * @param formData containing files, parameter should be the same as is in endpoint
     * @param userId UserId to identify who has requested
     * @returns 
     */
    public PostFile = (formData: FormData, userId: number):Observable<any> => this.http.post(`/api/legal/v1`,formData, {
        reportProgress: true,
        params: {
            "userId":userId
        },
        observe:'events'
    }).pipe<any>(this.mapSuccessResponse())

    /**
     * Get access token from remote
     * @returns {Observable<TokenAuth>} Access token
     */
    public ReturnAccessToken = (): Observable<TokenAuth> => 
    {
        let body= new URLSearchParams({
        "client_id":environment.security.identity.clientId,
        "client_secret": environment.security.privateKey,
        "grant_type":'client_credentials',
        "scope":environment.security.identity.scope});

        return this.http.post(`${environment.security.identity.domain}/connect/token`, body.toString(), {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            }).pipe<TokenAuth>(this.mapSuccessResponse());
    }

    //#region Helpers
    private GetQueryUrl(obj) {
        var url = Object.keys(obj).map(m => {
            return encodeURIComponent(m) + "=" + encodeURIComponent(obj[m]);
        });
        return url.join("&");
    }

    private handleSuccessResult<T>(data: HttpResponse<T>): HttpResponse<T> {
        return data;
    }

    private mapSuccessResponse<T>(): any {
        return map((data: HttpResponse<T>) => this.handleSuccessResult<T>(data));
    }
    //#endregion
}


export interface TokenAuth{
    access_token:string;
    expires_in: number;
    scope: string;
    token_type: string;
}
