/**
 * pokud je status jiny nez nula, tak nepokracuje dal nakup, ale zobzazi se dialog
     1 - text doda Franta, ale v principu pujde o odstavec textu, jeden checkbox a tlacitko
     2 - nahrani dokladu (na zvazeni je moznost scanu z kamery na poctaci)
     10 - jen hlaska pockejte a tlaciko rozumim, ktere dialog zavre
     99 - hlaska ve smyslu - bohuzel s Vami nemuzeme uzavrit obchod a tlacitko uzavrit 
 */
export enum AuthorizationType {
    NOT_NECESSARY,
    LITE,
    FULL,
    IN_PROGRESS=10,
    REJECTED=99
}