import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
}) export class AuthorizationPupupService {

    private popupClosedBhw = new BehaviorSubject(false);
    public PopupClosed = this.popupClosedBhw.asObservable();


    constructor() { }


    authPopupClosed = () => this.popupClosedBhw.next(true);
}