<h2 mat-dialog-title>{{data.title}}</h2>
<ng-container [ngSwitch]="dialogState">
    <mat-dialog-content *ngSwitchCase="0">
        <p i18n>Vaše žádost se schvaluje. Prosím o strpení.</p>
    </mat-dialog-content>
    <mat-dialog-content *ngSwitchCase="2">
        <p i18n>Vaše žádost nebyla schválena.</p>
    </mat-dialog-content>
    <mat-dialog-content *ngSwitchDefault class="mat-topography">
        <p class="mb-2">{{data.description}}</p>

        @if(dialogType == 1){
            <mat-stepper orientation="vertical" [linear]="true" #basicStepper>
                <mat-step [stepControl]="detailsForm">
                    <form class="mt-3" [formGroup]="detailsForm">
                        <ng-template matStepLabel i18n>Vaše totožnost</ng-template>
                        <div class="row">
                            <mat-form-field appearance="fill" class="col-12 col-md-6">
                                <mat-label i18n>Jméno, příjmení</mat-label>
                                <input i18n-placeholder matInput placeholder="Vaše jméno a příjmení" formControlName="name">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-12 col-md-6">
                                <mat-label i18n>Váš telefon</mat-label>
                                <input i18n-placeholder matInput placeholder="Telefon" formControlName="phone" type="tel">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-12">
                                <mat-label i18n>Váš e-mail</mat-label>
                                <input i18n-placeholder matInput placeholder="E-mail" formControlName="email" type="email">
                            </mat-form-field>
                        </div>
                        <div>
                            <button *ngIf="detailsForm.valid" mat-raised-button color="primary" matStepperNext>
                                <span i18n>Postoupit k souhlasu</span>
                                <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>
                    </form>
                </mat-step>
                    <mat-step [stepControl]="formBasicAuth">
                        <form [formGroup]="formBasicAuth">
                            <ng-template matStepLabel i18n>Pro další postup je potřeba projevit souhlas s našimi podmínkami používání.</ng-template>
                            <div class="my-2">
                                <mat-label i18n>Souhlasím</mat-label>
                                    <mat-checkbox formControlName="agreement"></mat-checkbox>
                            </div>
                            <div>
                                <button 
                                (click)="basicFormSubmited($event)"
                                *ngIf="formBasicAuth.valid" 
                                mat-raised-button 
                                color="primary">
                                    <span i18n>Odeslat souhlas</span>
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                            </div>
                        </form>
                    </mat-step>
                <mat-step>
                        <ng-template matStepLabel i18n>Hotovo</ng-template>
                        <p i18n>Děkujeme již je možné nakupovat.</p>
                    
                    <!-- <div>
                         <button mat-button matStepperPrevious>Back</button>
                         <button mat-button (click)="stepper.reset()">Reset</button>
                    </div> -->
                </mat-step>
            </mat-stepper>
        } @else if(dialogType == 2){
            <mat-stepper orientation="vertical" [linear]="true" #stepper>
                <mat-step [stepControl]="detailsForm">
                    <form class="mt-3" [formGroup]="detailsForm">
                        <ng-template matStepLabel i18n>Vaše totožnost</ng-template>
                        <div class="row">
                            <mat-form-field appearance="fill" class="col-12 col-md-6">
                                <mat-label i18n>Jméno, příjmení</mat-label>
                                <input i18n-placeholder matInput placeholder="Vaše jméno a příjmení" formControlName="name">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-12 col-md-6">
                                <mat-label i18n>Váš telefon</mat-label>
                                <input i18n-placeholder matInput placeholder="Telefon" formControlName="phone" type="tel">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-12">
                                <mat-label i18n>Váš e-mail</mat-label>
                                <input i18n-placeholder matInput placeholder="E-mail" formControlName="email" type="email">
                            </mat-form-field>
                        </div>
                        <div>
                            <button *ngIf="detailsForm.valid" mat-raised-button color="primary" matStepperNext>
                                @if(dialogType == 2){
                                    <span i18n>Naskenovat doklady</span>
                                } @else if(dialogType == 1){
                                    <span i18n>Postoupit k souhlasu</span>
                                }
                                <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>
                    </form>
                </mat-step>
                    <mat-step [stepControl]="proofFormA">
                        <form class="mt-3" [formGroup]="proofFormA">
                            
                            <ng-template matStepLabel i18n>Doklady (občanský, řidičský průkaz ...)</ng-template>
                            
                            <div class="my-2">
                                <label for="front"i18n>Nahrajte zadní stranu dokladu</label>
                                <!-- <input multiple formControlName="proof" accept="{{fileTypeAvalible.join(',')}}" class="form-control rounded-0" type="file" (change)="add($event.target.files)"> -->
                                <input 
                                    type="file" 
                                    aria-hidden="false"
                                    multiple="false"
                                    formControlName="photoA" 
                                    accept="{{fileTypeAvalible.join(',')}}" 
                                    class="form-control rounded-0" 
                                    (change)="add($event.target.files,0)">
                                <mat-hint class="small" i18n>Povolené typy souborů {{fileTypeAvalible.join(',')}}, datový limit na jednu fotografii je {{fileLimit}} MB</mat-hint>
                                <!-- <mat-progress-bar *ngIf="true" mode="determinate" value="1"></mat-progress-bar> -->
                            </div>
            
                            <app-file-result [files]="filesToSendA" [type]="0" (OnRemoved)="removed($event)"></app-file-result>
            
                            @if(proofFormA.get('photoA')?.value){
                                <div class="my-2">
                                    <label for="front"i18n>Nahrajte přední stranu dokladu</label>
                                    <!-- <input multiple formControlName="proof" accept="{{fileTypeAvalible.join(',')}}" class="form-control rounded-0" type="file" (change)="add($event.target.files)"> -->
                                    <input 
                                        aria-hidden="false"
                                        type="file" 
                                        multiple="false"
                                        formControlName="photoB" 
                                        accept="{{fileTypeAvalible.join(',')}}" 
                                        class="form-control rounded-0" 
                                        (change)="add($event.target.files,1)">
                                    <mat-hint class="small" i18n>Povolené typy souborů {{fileTypeAvalible.join(',')}}, datový limit na jednu fotografii je {{fileLimit}} MB</mat-hint>
                                    <!-- <mat-progress-bar *ngIf="true" mode="determinate" value="1"></mat-progress-bar> -->
                                </div>
                
                                <app-file-result [files]="filesToSendB" [type]="1" (OnRemoved)="removed($event)"></app-file-result>
                            }
                            
            
                            <div *ngIf="!uploading; else uploadTemplate">
                                <button mat-button matStepperPrevious i18n>Zpět</button>
                                <!-- <button mat-raised-button color="primary" *ngIf="!uploaded && proofFormA.controls['proof'].getError('required') !== true" (click)="uploadAll()" i18n>Odeslat</button> -->
                                <button (click)="formSubmited()" *ngIf="proofFormA.valid" mat-raised-button color="primary">
                                    <span i18n>Pokračovat</span>
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                            </div>
        
                            <ng-template #uploadTemplate>
                                <p>Odesílám data..</p>
                                <mat-progress-bar *ngIf="true" mode="determinate" value="{{uploadProgress}}"></mat-progress-bar>
                            </ng-template>
                        </form>
                    </mat-step>
                <mat-step>
                        <ng-template matStepLabel i18n>Hotovo</ng-template>
                        <p i18n>Jakmile budeme mít vše ověřeno, ihned Vás budeme kontaktovat prostřednictvím e-mailu.</p>
                    
                    
                    <!-- <div>
                         <button mat-button matStepperPrevious>Back</button>
                         <button mat-button (click)="stepper.reset()">Reset</button>
                    </div> -->
                </mat-step>
            </mat-stepper>
        }

        
    </mat-dialog-content>
</ng-container>
<mat-dialog-actions>
    <button (click)="authPopupClosed($event)" mat-raised-button color="primary" mat-dialog-close i18n>Zavřít</button>
</mat-dialog-actions>
